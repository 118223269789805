import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useOscIncident from "../HooksUtil/useOscIncident";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Modal,
  Select,
  Button,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalCustomStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "50%",
    padding: "1rem 1rem 0rem",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    border: "2px solid rgba(4, 31, 65, 0.1)",
    borderRadius: "0.2rem",
    boxShadow: "24",
    [theme.breakpoints.down("xs")]: {
      top: "53%",
      width: "80%",
    },
  },
  inputLabel: {
    paddingBottom: "0.5rem",
    paddingTop: "0.8rem",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      height: "2rem",
    },
  },
  queryTextArea: {
    height: "5rem",
    "& .MuiOutlinedInput-multiline": {
      height: "5rem",
      padding: "0px",
      borderRadius: "0px",
      alignItems: "normal",
      paddingTop: "0.6rem",
    },
  },
  selectDropdown: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "0px",
    width: "100%",
    height: "2rem",
  },
  disabled: {
    backgroundColor: "#68788D",
    borderColor: "#68788D",
    color: theme.palette.common.white,
    textFillColor: "#68788D",
  },
  spinner: {
    display: "block",
    color: theme.palette.common.blue,
    height: "4rem !important",
    width: "4rem !important",
    margin: "3rem",
  },
  button: {
    alignItems: "center",
    margin: "1rem",
    padding: "0rem 1rem",
    cursor: "pointer",
    justifyContent: "center",
    borderRadius: "999px !important",
  },
  submitButton: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
    },
    "&:disabled": {
      backgroundColor: "#68788D",
      color: theme.palette.common.white,
    },
  },
  createIncidentErrorMsg: {
    color: "#FF0000",
    margin: "3rem",
  },
  queryTextValidationBox: {
    fontSize: "11px",
    margin: "0px",
    padding: "0px",
  },
  errorMessage: {
    color: "#FF0000",
    alignItems: "right",
    fontSize: "11px",
    margin: "0px",
    height: "0.1rem",
  },
  characterCounter: {
    float: "right",
    fontSize: "11px",
    margin: "0px",
  },
  required: {
    color: "#FF0000",
    fontWeight: "bold",
  },
}));

export const CreateIncidentForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { checkIfUserEmailIdExists, fetchOscIncidentIssueTypeLevel1List } =
    useOscIncident();
  const [isVerifyingEmailId, setIsVerifyingEmailId] = useState(false);
  const [
    isFetchingOscIncidentIssueTypeLevel1List,
    setIsFetchingOscIncidentIssueTypeLevel1List,
  ] = useState(true);
  const [oscIncidentIssueTypeLevel1List, setOscIncidentIssueTypeLevel1List] =
    useState([]);
  const {
    isModalOpen,
    setIsModalOpen,
    chatBotAppConfig,
    handleCreateIncident,
    setIsIncidentCreated,
  } = props;

  const queryTextMaxLimit = 2000;
  const onlyDigitsRegex = /^[\d]*$/;
  const validEmailAddrRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,3}$/;
  const queryTextExcludedCharRegex = /[|&%#!*~]/;

  const VENDOR_FIRST_NAME_INPUT = "vendorFirstNameInput";
  const VENDOR_LAST_NAME_INPUT = "vendorLastNameInput";
  const VENDOR_EMAIL_ID_INPUT = "vendorEmailIdInput";
  const QUERY_TYPE_OBJ_INPUT = "queryTypeObjInput";
  const QUERY_TEXT_INPUT = "queryTextInput";

  const [vendorFirstName, setVendorFirstName] = useState("");
  const [vendorLastName, setVendorLastName] = useState("");
  const [vendorNumber, setVendorNumber] = useState("");
  const [vendorEmailId, setVendorEmailId] = useState("");
  const [queryTypeObj, setQueryTypeObj] = useState({});
  const [queryText, setQueryText] = useState("");

  const [vendorFirstNameErrMsg, setVendorFirstNameErrMsg] = useState("");
  const [vendorLastNameErrMsg, setVendorLastNameErrMsg] = useState("");
  const [vendorEmailIdErrMsg, setVendorEmailIdErrMsg] = useState("");
  const [queryTypeObjErrMsg, setQueryTypeObjErrMsg] = useState("");
  const [queryTextErrMsg, setqueryTextErrMsg] = useState("");

  useEffect(() => {
    fetchOscIncidentIssueTypeLevel1List()
      .then((incidentTypeList) => {
        setOscIncidentIssueTypeLevel1List(incidentTypeList);
        setIsFetchingOscIncidentIssueTypeLevel1List(false);
      })
      .catch((err) => {
        setOscIncidentIssueTypeLevel1List([]);
      });
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    const payload = {
      ...{
        vendorFirstName,
        vendorLastName,
        vendorNumber,
        vendorEmailId,
        queryText,
      },
      queryTypeId: queryTypeObj?.id,
      queryType: t(queryTypeObj?.label),
    };

    if (chatBotAppConfig?.EnableGoogleCaptcha) {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        handleCreateIncident(
          null,
          null,
          handleModalClose,
          setIsIncidentCreated
        );
      }
      try {
        const token = await executeRecaptcha();
        if (token) {
          handleCreateIncident(
            payload,
            token,
            handleModalClose,
            setIsIncidentCreated
          );
        } else {
          console.log("Recaptcha Token not yet available");
          handleCreateIncident(
            null,
            null,
            handleModalClose,
            setIsIncidentCreated
          );
        }
      } catch (err) {
        console.log("Execute recaptcha not successfull");
        handleCreateIncident(
          null,
          null,
          handleModalClose,
          setIsIncidentCreated
        );
      }
    } else {
      handleCreateIncident(
        payload,
        null,
        handleModalClose,
        setIsIncidentCreated
      );
    }
  }, [
    executeRecaptcha,
    vendorFirstName,
    vendorLastName,
    vendorNumber,
    vendorEmailId,
    queryTypeObj,
    queryText,
  ]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    resetModalState();
  };

  const resetModalState = () => {
    setVendorFirstName("");
    setVendorLastName("");
    setVendorNumber("");
    setVendorEmailId("");
    setQueryTypeObj({});
    setQueryText("");
    setVendorFirstNameErrMsg("");
    setVendorLastNameErrMsg("");
    setVendorEmailIdErrMsg("");
    setQueryTypeObjErrMsg("");
    setqueryTextErrMsg("");
  };

  const handleVendorFirstNameChange = (event) => {
    validateInput(event.target.name, event.target.value);
    setVendorFirstName(event.target.value);
  };

  const handleVendorLastNameChange = (event) => {
    validateInput(event.target.name, event.target.value);
    setVendorLastName(event.target.value);
  };

  const handleVendorNumberChange = (event) => {
    if (onlyDigitsRegex.test(event.target.value)) {
      setVendorNumber(event.target.value);
    }
  };

  const handleVendorEmailIdChange = (event) => {
    validateInput(event.target.name, event.target.value);
    setVendorEmailId(event.target.value);
  };

  const handleQueryTypeObjChange = (event) => {
    validateInput(event.target.name, event.target.value);
    setQueryTypeObj(event.target.value);
  };

  const handleQueryTextChange = (event) => {
    validateInput(event.target.name, event.target.value);
    setQueryText(event.target.value);
  };

  const validateInput = (inputName, inputValue) => {
    switch (inputName) {
      case VENDOR_FIRST_NAME_INPUT:
        if (!inputValue || inputValue?.trim()?.length == 0) {
          setVendorFirstNameErrMsg(
            t("INCIDENT_MGMT.FIRST_NAME_REQUIRED_ERROR_MESSAGE")
          );
        } else {
          setVendorFirstNameErrMsg("");
        }
        break;
      case VENDOR_LAST_NAME_INPUT:
        if (!inputValue || inputValue?.trim()?.length == 0) {
          setVendorLastNameErrMsg(
            t("INCIDENT_MGMT.LAST_NAME_REQUIRED_ERROR_MESSAGE")
          );
        } else {
          setVendorLastNameErrMsg("");
        }
        break;
      case VENDOR_EMAIL_ID_INPUT:
        if (!inputValue || inputValue?.length == 0) {
          setVendorEmailIdErrMsg(
            t("INCIDENT_MGMT.EMAIL_ID_REQUIRED_ERROR_MESSAGE")
          );
        } else if (!validEmailAddrRegex.test(inputValue)) {
          setVendorEmailIdErrMsg(
            t("INCIDENT_MGMT.EMAIL_ID_INVALID_ERROR_MESSAGE")
          );
        } else {
          setVendorEmailIdErrMsg("");
        }
        break;
      case QUERY_TYPE_OBJ_INPUT:
        if (
          !inputValue?.label ||
          inputValue?.label?.length == 0 ||
          !inputValue?.id ||
          inputValue?.id <= 0
        ) {
          setQueryTypeObjErrMsg(
            t("INCIDENT_MGMT.QUERY_TYPE_REQUIRED_ERROR_MESSAGE")
          );
        } else {
          setQueryTypeObjErrMsg("");
        }
        break;
      case QUERY_TEXT_INPUT:
        if (!inputValue || inputValue?.trim()?.length == 0) {
          setqueryTextErrMsg(
            t("INCIDENT_MGMT.QUERY_TEXT_REQUIRED_ERROR_MESSAGE")
          );
        } else if (queryTextExcludedCharRegex.test(inputValue)) {
          setqueryTextErrMsg(
            t("INCIDENT_MGMT.QUERY_TEXT_INVALID_ERROR_MESSAGE")
          );
        } else {
          setqueryTextErrMsg("");
        }
        break;
    }
  };

  const validateFormData = () => {
    return (
      vendorFirstName &&
      vendorFirstName.length > 0 &&
      vendorLastName &&
      vendorLastName.length > 0 &&
      vendorEmailId &&
      vendorEmailId.length > 0 &&
      validEmailAddrRegex.test(vendorEmailId) &&
      queryTypeObj?.label &&
      queryTypeObj?.label?.length > 0 &&
      queryTypeObj?.id &&
      queryTypeObj?.id > 0 &&
      queryText &&
      queryText.length > 0 &&
      !queryTextExcludedCharRegex.test(vendorEmailId)
    );
  };

  const verifyUserEmailId = (event) => {
    if (
      isModalOpen &&
      event?.target?.value &&
      validEmailAddrRegex.test(event.target.value)
    ) {
      setIsVerifyingEmailId(true);
      checkIfUserEmailIdExists(event.target.value)
        .then((res) => {
          setVendorEmailIdErrMsg("");
          setIsVerifyingEmailId(false);
        })
        .catch((err) => {
          console.error(err);
          setVendorEmailIdErrMsg("Email ID does not exist");
          setIsVerifyingEmailId(false);
        });
    }
  };

  const handleSubmit = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (validateFormData()) {
      handleReCaptchaVerify();
    } else {
      validateInput(VENDOR_FIRST_NAME_INPUT, vendorFirstName);
      validateInput(VENDOR_LAST_NAME_INPUT, vendorLastName);
      validateInput(VENDOR_EMAIL_ID_INPUT, vendorEmailId);
      validateInput(QUERY_TYPE_OBJ_INPUT, queryTypeObj);
      validateInput(QUERY_TEXT_INPUT, queryText);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        className={classes.modalCustomStyle}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h4" component="h4" mt={10}>
          {t("INCIDENT_MGMT.SEEK_INCIDENT_DETAILS_MESSAGE")}
        </Typography>
        <hr />
        <InputLabel
          htmlFor="vendorFirstNameInput"
          className={classes.inputLabel}
        >
          {t("INCIDENT_MGMT.SEEK_CUSTOMER_NAME_MESSAGE")}
          <span className={classes.required}>*</span> :
        </InputLabel>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <TextField
              id="vendorFirstNameInput"
              name="vendorFirstNameInput"
              className={classes.textField}
              type="search"
              placeholder={t("INCIDENT_MGMT.SEEK_CUSTOMER_FIRST_NAME_MESSAGE")}
              variant="outlined"
              value={vendorFirstName}
              onChange={handleVendorFirstNameChange}
            />
            <p className={classes.errorMessage}>{vendorFirstNameErrMsg}</p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="vendorLastNameInput"
              name="vendorLastNameInput"
              className={classes.textField}
              type="search"
              placeholder={t("INCIDENT_MGMT.SEEK_CUSTOMER_LAST_NAME_MESSAGE")}
              variant="outlined"
              value={vendorLastName}
              onChange={handleVendorLastNameChange}
            />
            <p className={classes.errorMessage}>{vendorLastNameErrMsg}</p>
          </Grid>
        </Grid>
        <InputLabel htmlFor="vendorNumberInput" className={classes.inputLabel}>
          {t("INCIDENT_MGMT.SEEK_CUSTOMER_VENDOR_NUMBER_MESSAGE")}
        </InputLabel>
        <TextField
          id="vendorNumberInput"
          name="vendorNumberInput"
          className={classes.textField}
          type="search"
          variant="outlined"
          value={vendorNumber}
          onChange={handleVendorNumberChange}
        />
        <InputLabel htmlFor="vendorEmailIdInput" className={classes.inputLabel}>
          {t("INCIDENT_MGMT.SEEK_CUSTOMER_EMAIL_ID_MESSAGE")}
          <span className={classes.required}>*</span> :
        </InputLabel>
        <TextField
          id="vendorEmailIdInput"
          name="vendorEmailIdInput"
          className={classes.textField}
          type="search"
          variant="outlined"
          value={vendorEmailId}
          onChange={handleVendorEmailIdChange}
          onBlur={verifyUserEmailId}
        />
        <p className={classes.errorMessage}>{vendorEmailIdErrMsg}</p>
        <InputLabel htmlFor="queryTypeObjInput" className={classes.inputLabel}>
          {t("INCIDENT_MGMT.SEEK_QUERY_CATEGORY_MESSAGE")}
          <span className={classes.required}>*</span> :
        </InputLabel>
        <Select
          id="queryTypeObjInput"
          name="queryTypeObjInput"
          value={queryTypeObj}
          onChange={handleQueryTypeObjChange}
          className={classes.selectDropdown}
          disabled={
            isFetchingOscIncidentIssueTypeLevel1List &&
            oscIncidentIssueTypeLevel1List?.length > 0
          }
        >
          {oscIncidentIssueTypeLevel1List &&
            oscIncidentIssueTypeLevel1List.length > 0 &&
            oscIncidentIssueTypeLevel1List.map(
              (oscIncidentIssueTypeLevel1, idx) => (
                <MenuItem
                  key={oscIncidentIssueTypeLevel1?.label + "_" + idx}
                  value={oscIncidentIssueTypeLevel1}
                >
                  {t(oscIncidentIssueTypeLevel1?.label)}
                </MenuItem>
              )
            )}
        </Select>
        <p className={classes.errorMessage}>{queryTypeObjErrMsg}</p>
        <InputLabel htmlFor="queryTextInput" className={classes.inputLabel}>
          {t("INCIDENT_MGMT.SEEK_QUERY_MESSAGE")}
          <span className={classes.required}>*</span> :
        </InputLabel>
        <TextField
          id="queryTextInput"
          name="queryTextInput"
          multiline
          maxRows={3}
          variant="outlined"
          value={queryText}
          inputProps={{ maxLength: queryTextMaxLimit }}
          onChange={handleQueryTextChange}
          className={classes.queryTextArea}
        />
        <div className={classes.queryTextValidationBox}>
          <span className={classes.errorMessage}>{queryTextErrMsg}</span>
          <span
            className={classes.characterCounter}
          >{`${queryText.length}/${queryTextMaxLimit}`}</span>
        </div>
        <Grid container direction="row" justifyContent="center">
          <Button
            size="small"
            className={classes.button}
            variant="contained"
            color="error"
            onClick={handleModalClose}
          >
            {t("CHATBOT_COMMONS.CANCEL_TEXT")}
          </Button>
          <Button
            size="small"
            className={`${classes.button} ${classes.submitButton}`}
            variant="contained"
            disabled={
              vendorFirstNameErrMsg.length > 0 ||
              vendorLastNameErrMsg.length > 0 ||
              vendorEmailIdErrMsg.length > 0 ||
              queryTypeObjErrMsg.length > 0 ||
              queryTextErrMsg.length > 0 ||
              isVerifyingEmailId
            }
            onClick={handleSubmit}
          >
            {t("CHATBOT_COMMONS.SUBMIT_TEXT")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
