import { useState, useEffect } from "react";
import md5 from "md5";

export default function useCreateChatStorageId() {
    const [chatID, setChatID] = useState("");
    const getNewChatId = () => {
        const timestamp = new Date().getTime();
        const hashedId = md5(timestamp);
        localStorage.setItem("chatStorageId", hashedId);
        return hashedId;
    };

    useEffect(() => {
        const chatStorageId = localStorage.getItem("chatStorageId");
        if(chatStorageId === null){
            const hashedId = getNewChatId();
            setChatID(hashedId);
        }else{
            setChatID(chatStorageId);
        }
    }, []);
    return {chatID} ;
}