import axios from "axios";
import envDetails from "./Config";

const getEnvInfo = async () => {
    try {
        const response = await axios.get(`/getEnvInfo`);
        console.log("response", response);
        return response.data;
    } catch (error) {
        console.log("Error axios-013", error.message);
    }
};

export const { feBaseUrl, env } = getEnvInfo().then();

export const displayChipOptions = (message) => {
    const nonChipOptionInputTypes = [
        "autocomplete-async",
        "autocomplete-async-filterless",
        "autocomplete-multi-async",
        "autocomplete-multi-select",
        "autocomplete-provide-feedback",
        "autocomplete",
        "date",
        "dynamic-chips",
        "loader",
    ];

    return message?.options?.length > 0 && message?.options?.length < 25 &&
        (
            !message.inputDataType ||
            nonChipOptionInputTypes.indexOf(message.inputDataType.toLowerCase()) === -1
        );
};

export const convertStringArrayToObjectArray = (stringCollection) =>
    stringCollection.map((item) => ({ label: item }));

export const alphabetSort = (list, key) => list.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));


export const renewSession = () =>
    axios.get(`/api/session/idletime`).then(m=>console.log(m))
        .catch((error) => {
            console.log("Error axios-013", error.message);
        });
export const createSession = () =>{
    return axios.get(`/api/session/create`);
}    
export const getChatBotAppConfig = () => {
  return axios.get(`/api/appConfig`);
}; 

export const checkIfGoogleApisReachable = () => {
  return axios.get(`/checkIfGoogleApisReachable`);
}; 

export const checkIfGoogleApisReachable2 = () => {
  return fetch("https://www.google.com/recaptcha/api.js");
}; 

export const refreshSession = () =>
    axios.get(`/api/session/refresh`).then(m=>console.log(m))
        .catch((error) => {
            console.log("Error axios-014", error.message);
        });
export const deleteSession = () =>
    axios.get(`/api/session/delete`).then(m=>console.log(m))
        .catch((error) => {
            console.log("Error axios-014", error.message);
        });
export const getLangCookie = () => {
      // extracting sustportallangcode attribute value from cookie within domain '.walmart.com'
      const ck = document.cookie;
      if (ck) {
        const ckArray = ck.split(";");
        const cklang = ckArray.find((y) => y.includes("sustportallangcode"));
        if (cklang) {
          const ckLangArray = cklang.split("sustportallangcode=");
          return ckLangArray[1];
        }
      }
      return null;
    }