import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    borderless: {
        backgroundColor: "inherit",
        border: "none",
        boxShadow: "none",
    },
    primary: {
        backgroundColor: `${theme.palette.common.buttonPrimaryBackground} !important`,
        border: `1px solid ${theme.palette.common.buttonPrimaryBackground} !important`,
        color: `${theme.palette.common.buttonPrimary} !important`,
        "&:disabled": {
            backgroundColor: `${theme.palette.common.buttonDisabledBackground} !important`,
            border: `1px solid ${theme.palette.common.buttonDisabledBackground} !important`,
            color: `${theme.palette.common.buttonDisabled} !important`
        },
        "&:hover": {
            backgroundColor: `${theme.palette.common.buttonSecondary} !important`,
            border: `1px solid ${theme.palette.common.buttonSecondaryBackground} !important`,
            color: `${theme.palette.common.buttonSecondaryBackground} !important`
        }
    },
    root: {
        borderRadius: "999px !important",
        fontWeight: "bold",
        padding: `1px ${theme.spacing(2)}px !important`,
        textTransform: "none !important",
    },
    secondary: {
        backgroundColor: `${theme.palette.common.buttonSecondaryBackground} !important`,
        border: `1px solid ${theme.palette.common.buttonSecondary} !important`,
        color: `${theme.palette.common.buttonSecondary} !important`,
        "&:disabled": {
            borderColor: `${theme.palette.common.buttonDisabled} !important`,
            color: `${theme.palette.common.buttonDisabled} !important`
        },
        "&:hover": {
            backgroundColor: `${theme.palette.common.buttonSecondary} !important`,
            color: `${theme.palette.common.buttonSecondaryBackground} !important`
        }
    }
}));

const CustomButton = (props) => {
    const classes = useStyles();

    const {
        children,
        className,
        handleClick,
        variant,
        ...rest
    } = props;

    return (
        <Button
            className={`${classes.root} ${classes[variant]} ${className}`}
            onClick={
                // istanbul ignore next
                (event) => handleClick(event, children)
            }
            {...rest}
        >
            {children}
        </Button>
    );
};

CustomButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    handleClick: PropTypes.func,
    size: PropTypes.string,
    variant: PropTypes.string,
};

CustomButton.defaultProps = {
    size: "medium",
    variant: "primary"
};

export default CustomButton;
