import i18n from "../../i18n";
export const getBaseBotPayload = () => {
    return {
        sender: "bot",
        receiver: "user",
        text: null,
        subtext: null,
        hyperlinkurl: null,
        options: [],
        qnaResponse: null,
        qnaLatency: null,
        qnaContext: null,
        qnaSemanticSimilarity: null,
        converseResponse: null,
        converseIntentName: null,
        converseLatency: null
    };
};

export const getBaseUserPayload = (text) => {
    return {
        sender: "user",
        receiver: "bot",
        type: "query",
        text: text,
    };
};

export const getLoadingMsgBotPayload = () => {
    const botPayload = getBaseBotPayload();
    botPayload["inputDataType"] = "loader";
    return botPayload;
};
const botPayload = {
    text: "Hi",
    user: {
        "win": "GST11111",
        "userId": "aaaaaa",
        "email": "aaaaaa@homeoffice.wal-mart.com"
    },
    conversationId: "111",
    noIntentCounter: 0
};
export const getBotBackendPayload = (text, convId,noIntentCounter) => {
    const backendPayload = { ...botPayload };
    backendPayload.text = text;
    backendPayload.conversationId = convId;
    backendPayload.noIntentCounter = noIntentCounter? noIntentCounter: 0
    console.log(backendPayload);
    return backendPayload;
};
export const getBotErrorMessagePayload = () => {
    return {
        sender: "bot",
        receiver: "user",
        text: i18n.t("CHATBOT_COMMONS.BOT_ERROR_MESSAGE1"),
        subtext: i18n.t("CHATBOT_COMMONS.BOT_ERROR_MESSAGE2"),
    };
};

export const getBotUnidentifiedIntentMessagePayload = () => {
    return {
        sender: "bot",
        receiver: "user",
        text: "I'm having trouble understanding your question. Can you type it a different way for me?",
        subtext: "",
    };
};

export const getBotFeedbackMessagePayload = (showNps) => {
    const botMessage = getBaseBotPayload();
    botMessage.feedbackType = showNps? "nps_feedback" : "thumbs_feedback";
    botMessage.text = showNps
        ? "How likely are you to recommend chat to a team member?"
        : i18n.t("CHATBOT_COMMONS.SEEK_FEEDBACK_MESSAGE");
    return botMessage;
};

export const getBotStartFeedbackPayload = (showNps) => {
    const botMessage = getBaseBotPayload();
    botMessage.subtext = i18n.t("CHATBOT_COMMONS.CHATBOT_TASK_MESSAGE");
    botMessage.options = [
      "CHATBOT_COMMONS.MENU_TEXT",
      "CHATBOT_COMMONS.I_AM_ALL_SET_TEXT",
    ];
    return botMessage;
};

export const getPostFeedbackMessagePayload = (name, npsSkip = false) => {
    const botMessage = getBaseBotPayload();
    botMessage.text = npsSkip
        ? "Is there anything else I can help you with?"
        : i18n.t("CHATBOT_COMMONS.FEEDBACK_THANKS_MESSSAGE");
    botMessage.options = [];
    return botMessage;
};

export const getFeedBackList = () => {
    return {
        sender: "bot",
        receiver: "user",
        subtext:
            "Please start typing to select the associate you would like to add job for",
        autoCompleteDisplayProps: ["Feedback_Badge_Name"],
        mainIntent: "additionaljob_add",
        options: [
            {
                Feedback_Badge_Name: "Achievement",
                Icon_Reference: "ICON-ACHIEVEMENT",
                ID: "FEEDBACK_BADGE-6-1",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Bright",
                Icon_Reference: "ICON-BRIGHT",
                ID: "FEEDBACK_BADGE-6-2",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Comment",
                Icon_Reference: "ICON-COMMENT",
                ID: "FEEDBACK_BADGE-6-3",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Creative",
                Icon_Reference: "ICON-CREATIVE",
                ID: "FEEDBACK_BADGE-6-4",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Technical",
                Icon_Reference: "ICON-TECHNICAL",
                ID: "FEEDBACK_BADGE-6-5",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Problem Solver",
                Icon_Reference: "ICON-PROBLEMSOLVER",
                ID: "FEEDBACK_BADGE-6-6",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Medal",
                Icon_Reference: "ICON-MEDAL",
                ID: "FEEDBACK_BADGE-6-7",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Resourceful",
                Icon_Reference: "ICON-RESOURCEFUL",
                ID: "FEEDBACK_BADGE-6-8",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Samaritan",
                Icon_Reference: "ICON-SAMARITAN",
                ID: "FEEDBACK_BADGE-6-9",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Strong",
                Icon_Reference: "ICON-STRONG",
                ID: "FEEDBACK_BADGE-6-10",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Thank You",
                Icon_Reference: "ICON-15-219",
                ID: "FEEDBACK_BADGE-6-11",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Innovative",
                Icon_Reference: "ICON-15-220",
                ID: "FEEDBACK_BADGE-6-12",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Educational",
                Icon_Reference: "ICON-15-221",
                ID: "FEEDBACK_BADGE-6-13",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Inspiring",
                Icon_Reference: "ICON-15-222",
                ID: "FEEDBACK_BADGE-6-14",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Teamwork",
                Icon_Reference: "ICON-15-223",
                ID: "FEEDBACK_BADGE-6-15",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "Fun!",
                Icon_Reference: "ICON-15-224",
                ID: "FEEDBACK_BADGE-6-16",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "General",
                Icon_Reference: "ICON-15-218",
                ID: "FEEDBACK_BADGE-6-17",
                Inactive: "0",
            },
            {
                Feedback_Badge_Name: "NONE",
                Icon_Reference: "",
                ID: "",
                Inactive: "0",
            },
        ],
        labelFormatting: {
            Feedback_Badge_Name: {
                bold: true,
            },
        },
        text: "A drug screen, background check, or license check may be needed if the newly added job requires it.",
        autoCompleteUniqueProps: ["Icon_ID"],
        inputDataType: "autocomplete-provide-feedback",
        intent: "add_job",
    };
};

export const disableChat = (userName) => {
    return {
        sender: "bot",
        receiver: "user",
        text: `I apologize for the inconvenience ${userName} but I am only able to support people managers and their delegates at this time. I hope to be able to serve you soon!`,
        subtext: "",
    };
};

export const getSubMenuOptions = (list) => {
    return {
        receiver: "user",
        sender: "bot",
        subtext: "",
        text: "What do you want to do?",
        options: list
    };
};

export const getFallbackResp = () => {
    return ["It seems that your request is not bringing up any responses available. Please try the \"Topics\" section to navigate to a solution." + 
    "If you cannot find your answers there, please send an email to <a href='mailto:corpsu@wal-mart.com' target=_blank>corpsu@wal-mart.com</a>." +
    "Response time should be within 48 hours after being received and processed."];
};
