import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Loader.css";

// istanbul ignore next
const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        padding: "10px 15px 5px",
    },
}));

const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className="dot-typing" />
        </div>
    );
};

export default Loader;
