import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import { NoEncryption } from "@material-ui/icons";
// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    htmlMarkDown: {
        // whiteSpace: 'normal',
        margin: 0,
        "& a": {
            color: theme.palette.common.lighterBlue,
            underline: 'None',
            "&:hover": {
                fontWeight: 550,
            }
        }
    }
}));

/**
 * Wrapper for react-markdown. Any plugins, component substitutions, etc. should
 * be performed here ONLY if at all possible. If your use case involves special
 * styling for components that are used elsewhere, consider alternatives first.
 */
function escapeHtml(text) {
    const map =
    {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&#039;": "'"
    };
    return text.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function(m) {
        return map[m];
    });
}
const Markdown = (props) => {
    const { text, ...rest } = props;
    const classes = useStyles();
    
    if (!text) {
        return null;
    }
    const processedText = escapeHtml(text);
    return (
        <div className={classes.htmlMarkDown}>{parse(processedText)}</div>
    );
};

Markdown.propTypes = {
    text: PropTypes.string
};

export default Markdown;

