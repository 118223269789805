import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import "../../App.css";
import { useTranslation } from "react-i18next";
import sendIcon from "../../assets/Send-Icon-Fab.svg";
import Button from "@material-ui/core/Button";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    chatUserInputOuterContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: "5px 0px",
        border: "1px solid #dadce0",
        borderRadius: "8px",
        marginBottom: "32px",
        marginTop: "16px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "10px",
        },
    },
    chatUserInputInnerContainer: {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.common.border,
        borderRadius: "999px",
        boxSizing: "border-box",
        height: "50px",
        margin: 0,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            backgroundColor: "#f8f8f8",
        }
    },
    disabled: {
        backgroundColor: theme.palette.common.inputDisabledBackground,
        borderColor: theme.palette.common.inputDisabled,
        color: theme.palette.common.inputDisabled,
        textFillColor: theme.palette.common.inputDisabled,
    },
    sendButton: {
        height: "16px",
        width: "20px"
    },
    sendButtonContainer: {
        alignItems: "center",
        backgroundColor: theme.palette.common.blue,
        marginRight:"2px",
        border: "none",
        borderRadius: "50%",
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        fontSize: "12px",
        height: "25px",
        justifyContent: "center",
        marginLeft: "5px",
        minHeight: "45px",
        minWidth: "45px",
        padding: 0,
        width: "50px",
        "&:disabled": {
            backgroundColor: `${theme.palette.common.buttonDisabledBackground} !important`,
            border: `1px solid ${theme.palette.common.buttonDisabledBackground} !important`,
            color: `${theme.palette.common.buttonDisabled} !important`,
            cursor: "default"
        }
    },
    unavailUserInputSection: {
        display: "none"
    }
}));

const ChatInput = forwardRef((props, ref) => {
    const {
        currentChatItem,
        inputType,
        isUserInputDisabled,
        isUserInputVisible,
        handleKeyPress,
        setInputFocus,
        setUserInputString,
        userInputString,
        selectedOption,
        selectOptions,
        setSelectedOption,
        setSelectOptions,
        handleClickOptions,
        handleFormSubmit
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isUserInputDisabled) {
            setInputFocus();
        }
    }, [inputType, isUserInputDisabled]);

    const renderInput = () => {
        switch (inputType) {
            case "text":
            default:
                return (
                    <TextField
                        disabled={isUserInputDisabled}
                        onChange={
                            // istanbul ignore next
                            (event) => setUserInputString(event.target.value)
                        }
                        onKeyDown={handleKeyPress}
                        placeholder={t("CHATBOT_COMMONS.PHRASE_INPUT_MESSAGE")}
                        inputRef={ref}
                        type="text"
                        value={userInputString}
                    />
                );
        }
    };

    return (
        <div
            className={isUserInputVisible ? classes.chatUserInputOuterContainer : classes.unavailUserInputSection}
        >
            <button
                className="optionsContainer"
                onClick={() => handleClickOptions()}
            >
                <p>{t("TOPICS_TEXT")}</p>
            </button>
            <div className={`${classes.chatUserInputInnerContainer} ${isUserInputDisabled && classes.disabled}`}>
                {renderInput()}
            </div>
            <button
                disabled={isUserInputDisabled}
                className={classes.sendButtonContainer}
                onClick={handleFormSubmit}
            >
                <img
                    alt="Submit User Input"
                    className={classes.sendButton}
                    src={sendIcon}
                />
            </button>
        </div>
    );
});

ChatInput.displayName = "ChatInput";

ChatInput.propTypes = {
    currentChatItem: PropTypes.object,
    handleKeyPress: PropTypes.func.isRequired,
    inputType: PropTypes.string,
    isUserInputDisabled: PropTypes.bool,
    isUserInputVisible: PropTypes.bool,
    setUserInputString: PropTypes.func.isRequired,
    userInputString: PropTypes.string,
    selectedOption: PropTypes.object,
    selectOptions: PropTypes.array,
    setSelectedOption: PropTypes.func.isRequired,
    setSelectOptions: PropTypes.func.isRequired,
    handleClickOptions: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
};

export default ChatInput;
