import React from "react";
import PropTypes from "prop-types";

import ChatWindow from "./ChatWindow";
import useCreateConversationId from "../HooksUtil/useCreateConversationId";
import useCreateChatStorageId from "../HooksUtil/useCreateChatStorageId";


const ChatRoot = (props) => {
    const { userInfo, isRRPermission, chatBotAppConfig, language } = props;
    const userId = userInfo.userId;
    const { convId } = useCreateConversationId(userId);
    const { chatID } =  useCreateChatStorageId();
  
    return (
        <ChatWindow
            userInfo={userInfo}
            convId={convId}
            isRRPermission={isRRPermission}
            chatStorageId={chatID}
            language={language}
            chatBotAppConfig={chatBotAppConfig}
        />
    );
};

ChatRoot.propTypes = {
    isRRPermission: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
};

export default ChatRoot;
