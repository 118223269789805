import axios from "axios";
export default function useQnaSearch() {
  const qnaSearch = (searchPayload, isAnLLMCall, language) => {
    let reqBody = {
      question: searchPayload?.question,
      id: searchPayload?.id,
      lookupName: searchPayload?.lookupName,
      isLlmCall: isAnLLMCall,
    };
    reqBody = JSON.parse(JSON.stringify(reqBody));
    try {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const url = "/api/qna/faq" + "?lang=" + language;
        axios
          .post(url, reqBody)
          .then((resp) => {
            if (resp?.data && resp?.data?.body) {
              const latency = new Date().getTime() - startTime;
              const qnaResponse = { ...resp.data, qnaLatency: latency };
              resolve(qnaResponse);
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (err) {
      console.log("Error AXIOS-QNA-001", err.message);
    }
  };
  return { qnaSearch };
}
