import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import ChipInput from "../ChatInputs/ChipInput";
import {
    getBaseUserPayload,
    getSubMenuOptions,
} from "../common/PayloadProvider";
import {
    alphabetSort,
} from "../../utils/utils";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    chipOptionsCard: {
        display: "inline-block",
        maxWidth: "calc(90% - 45px)",
        // maxWidth: "calc(100% - 0px)",
        wordWrap: "break-word",
        [theme.breakpoints.up("lg")]: {
            // maxWidth: "calc(55% - 45px)",
        },
        marginLeft: "6px",
    },
    chipOptionsSpacer: {
        minWidth: "65px",
        width: "72px"
    },
    chipOptionsText: {
        backgroundColor: "#e1edf9",
        borderRadius: "10px",
        borderBottomLeftRadius: "initial",
        color: theme.palette.common.black,
        padding: "0.75em",
    },
    chipOptionsWrapper: {
        display: "flex"
    },
    nonSubmitChipsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        // marginLeft: "-0.5em"
    },
    submitChipsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        marginLeft: "-0.5em",
        marginTop: theme.spacing(1.5)
    }
}));

const ChipMenu = (props) => {
    const {
        chatModel,
        handleFormSubmit,
        isRRPermission,
        levels,
        setChatModel,
        userInfo
    } = props;
    const classes = useStyles();
    const lastMessage = chatModel[chatModel.length - 1];

    const selectedMenu = (e, menu) => {
        const payload = getBaseUserPayload(menu.option, userInfo);
        if (menu.isLeaf) {
            handleFormSubmit(e, menu.option, null);
        } else if (menu.option.toLowerCase() === "Back".toLowerCase()) {
            if (menu.level === 2) {
                const list = levels.filter(row => row.level === 1);
                const botPayload = getSubMenuOptions(list);
                const newModel = [...chatModel];
                newModel[newModel.length - 1] = botPayload;
                setChatModel(newModel);
            } else {
                const list = alphabetSort(levels.filter(
                    row => row.level === menu.level - 1 &&
                        row.group.find(li => li === menu.group[menu.group.length - 1])
                ), "option");
                list.unshift({
                    level: menu.level - 1,
                    option: "Back"
                });
                const botPayload = getSubMenuOptions(list);
                const newModel = [...chatModel];
                newModel[newModel.length - 1] = botPayload;
                setChatModel(newModel);
            }
        } else {
            const list = alphabetSort(levels.filter(
                row => row.level === menu.level + 1 &&
                    row.group.find(li => li === menu.group[menu.group.length - 1])
            ), "option");
            setChatModel(
                // istanbul ignore next
                chatModel => [...chatModel, payload]
            );
            list.unshift({
                level: menu.level + 1,
                option: "Back",
                group: [menu.group[0]]
            });
            const botPayload = getSubMenuOptions(list);
            setChatModel(
                // istanbul ignore next
                chatModel => [...chatModel, botPayload]
            );
        }
    };

    return (
        <div className={classes.chipOptionsWrapper}>
            {/* <div className={classes.chipOptionsSpacer} /> */}
            <div className={classes.chipOptionsCard}>
                {lastMessage?.options &&
                    <div className={classes.nonSubmitChipsContainer}>
                        {lastMessage?.options?.map(option => {
                            return <ChipInput
                                handleClickOption={handleFormSubmit}
                                key={option.Icon_Reference ? option.Icon_Reference : option.option ? option.option : option}
                                option={option}
                                selectedMenu={selectedMenu}
                            />;
                        })}
                    </div>
                }

            </div>
        </div>
    );
};

ChipMenu.propTypes = {
    chatModel: PropTypes.array.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
    isRRPermission: PropTypes.bool,
    levels: PropTypes.array,
    setChatModel: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
};

export default ChipMenu;
