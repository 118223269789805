import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import ChatRoot from "../ChatWindow/ChatRoot";
import ChatHeader from "../ChatHeader/ChatHeader";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    chatArea: {
        height: "calc(100vh - 65px)",
        marginTop: "61px",
        maxWidth: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "&::-webkit-scrollbar": {
            width: "0.2em"
        },
        "&::-webkit-scrollbar-track": {
            background: "initial"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.common.blue,
            border: `3px solid ${theme.palette.common.blue}`,
            borderRadius: theme.spacing(2),
        },
        [theme.breakpoints.up("lg")]: {
            height: "calc(100vh - 71px)",
            marginTop: "4em",
        }
    },
    profileArea: {
        height: "60px",
        padding: theme.spacing(0, 1),
        position: "absolute",
        top: 0,
        width: "100%",
        backgroundColor: theme.palette.common.blue,
        [theme.breakpoints.up("lg")]: {
            height: "4em",
            padding: theme.spacing(2, 3),
        },
        [theme.breakpoints.down("xs")]: {
            backgroundColor: theme.palette.common.blue,
        }
    },
    serviceUnavailableArea: {
        marginTop: "7em",
        maxWidth: "100%",
        width: "100%"
    },
    containerStyle: {
        backgroundColor: "white",
        [theme.breakpoints.down("xs")]: {
            backgroundColor: "#f8f8f8",
        }
    }
}));

const ChatSection = (props) => {
    const classes = useStyles();
    // const { statusCode, workDay } = useContext(ErrorContext);
    const { userInfo, isRRPermission, chatBotAppConfig, lang } = props;
    const [language, setLanguage] = useState(lang);

    return (
        <Grid container direction="column" className={classes.containerStyle}>
            <Grid item container className={classes.profileArea}>
                <ChatHeader
                    lang={lang}
                    chatBotAppConfig={chatBotAppConfig}
                    reflectLanguageChange={setLanguage}
                />
            </Grid>
            <Grid item className={classes.chatArea}>
                <ChatRoot
                    isRRPermission={isRRPermission}
                    userInfo={userInfo}
                    chatBotAppConfig={chatBotAppConfig}
                    language={language}
                />
            </Grid>
        </Grid>
    );
};

ChatSection.propTypes = {
    isRRPermission: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
};

export default ChatSection;
