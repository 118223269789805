import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import axios from "axios";
import { getPostFeedbackMessagePayload } from "../common/PayloadProvider";

import CustomButton from "../common/CustomButton";
// import ErrorContext from "../../ErrorContext";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    best: {
        alignItems: "center",
        alignSelf: "flex-end",
        display: "flex",
        fontWeight: "bold",
        [theme.breakpoints.up("lg")]: {
            alignSelf: "center",
        }
    },
    btnSkip: {
        marginRight: theme.spacing(2.5),
        [theme.breakpoints.down("md")]: {
            marginRight: theme.spacing(1.25),
        },
        [theme.breakpoints.down("xs")]: {
            marginRight: theme.spacing(0.75),
        },
    },
    clear: {
        background: "none",
        border: "none",
        color: theme.palette.common.buttonSecondary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: "700",
        marginRight: theme.spacing(2.5),
        padding: 0,
        position: "relative",
        textDecoration: "underline",
        "&:hover": {
            color: theme.palette.common.blue,
        },
        top: theme.spacing(0.125),
        [theme.breakpoints.down("md")]: {
            marginRight: theme.spacing(1.25),
        },
    },
    end: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            justifyContent: "flex-end",
        },
    },
    feedbackArea: {
        borderColor: theme.palette.common.inputBorder,
        borderRadius: theme.spacing(0.5),
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        padding: theme.spacing(1),
        width: "97%",
        "&::placeholder": {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
        },
        "&:disabled": {
            backgroundColor: theme.palette.common.inputDisabledBackground,
            color: theme.palette.common.inputDisabled,
            textFillColor: theme.palette.common.inputDisabled,
        },
    },
    radioGroup: {
        justifyContent: "center"
    },
    radioLabel: {
        marginLeft: "0 !important",
        marginRight: "0.01px",
        "& .MuiButtonBase-root": {
            padding: "6px",
        },
    },
    radioSection: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
            flexWrap: "nowrap",
        },
    },
    textThanku: {
        margin: "1rem 0"
    },
    worst: {
        alignItems: "center",
        alignSelf: "flex-start",
        display: "flex",
        fontWeight: "bold",
        [theme.breakpoints.up("lg")]: {
            alignSelf: "center",
        }
    },
}));

const Nps = (props) => {
    const classes = useStyles();
    // const { setStatusCode } = useContext(ErrorContext);

    const { addChatPayload, name, setIsUserInputDisabled ,chatStorageId , handleMenuMessage ,channel} = props;

    const feedbackRating = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const [value, setValue] = useState(null);
    const [comment, setComment] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [feedbackSent, setFeedbackSent] = useState(false);

    useEffect(() => {
        setIsUserInputDisabled(false);
    }, []);

    const handleChange = (e) => {
        setValue(parseInt(e.target.value));
        setDisabled(false);
    };

    const handleTextChange = (e) => {
        setComment(e.target.value);
    };

    const clearValue = () => {
        setComment("");
        setValue("");
        setDisabled(true);
    };

    const submitFeedback = () => {
        const reqBody =    {
            "feedback_response": comment,   
            "rating": value,   
            "localStorageId": chatStorageId,
            "feedbackType": "nps_feedback",
            "channelId": channel
            }
        const payload = getPostFeedbackMessagePayload();
        addChatPayload(payload);
        setIsUserInputDisabled(false);
        setFeedbackSent(true);
        handleMenuMessage();
        console.log(reqBody)
        axios.post("/api/chathistory/saveUserFeedback", reqBody)
            .then((resp) => {
                console.log(resp)
            })
            .catch(err => {
                console.log("Error axios-005", err.message);
                // setStatusCode(404);
            });
    };

    const skipFeedback = () => {
        axios.get(`/api/chatModel/getChatOptions`)
            .then(response => {
                const payload = getPostFeedbackMessagePayload(name, true);
                payload.options = response.data;
                addChatPayload(payload);
                setFeedbackSent(true);
                setIsUserInputDisabled(false);
            })
            .catch(err => {
                console.log("Error axios-006", err.message);
                // setStatusCode(404);
            });
    };

    return (
        <div className="main">
            <FormControl
                className={classes.radioSection}
                component="fieldset"
            >
                <div className={classes.worst}>
                    Not Likely
                </div>
                <RadioGroup
                    aria-label="position"
                    className={classes.clsRadio}
                    defaultValue="top"
                    disabled={feedbackSent}
                    name="position"
                    onClick={handleChange}
                    row
                    value={value}
                >
                    {feedbackRating.map((radio, index) =>
                        <FormControlLabel
                            className={classes.radioLabel}
                            color={"secondary"}
                            control={<Radio />}
                            disabled={feedbackSent}
                            key={index}
                            label={radio}
                            labelPlacement="bottom"
                            value={radio}
                        />
                    )}
                </RadioGroup>
                <div className={classes.best}>
                    Very Likely
                </div>
            </FormControl>

            <div className={classes.textThanku}>
                Would you like to provide any additional feedback/suggestions?
            </div>

            <Grid container>
                <Grid item xs={12}>
                    <TextareaAutosize
                        aria-label="comments"
                        className={classes.feedbackArea}
                        disabled={feedbackSent}
                        minRows={5}
                        onChange={handleTextChange}
                        placeholder="Please Type your comments or suggestions here ..."
                        value={comment}
                    />
                </Grid>
            </Grid>

            {!feedbackSent ? (
                <div className={classes.end}>
                    <button
                        className={classes.clear}
                        id="clear"
                        onClick={clearValue}
                    >
                        Clear
                    </button>
                    {/* <CustomButton
                        className={classes.btnSkip}
                        handleClick={skipFeedback}
                        id="skip"
                        variant="secondary"
                    >
                        Skip
                    </CustomButton> */}
                    <CustomButton
                        className={classes.btnSubmit}
                        disabled={disabled}
                        handleClick={submitFeedback}
                        id="submit"
                        variant="primary"
                    >
                        Submit
                    </CustomButton>
                </div>
            ) : null}
        </div>
    );
};

Nps.propTypes = {
    addChatPayload: PropTypes.func.isRequired,
    name: PropTypes.string,
    setIsUserInputDisabled: PropTypes.func.isRequired,
    chatStorageId: PropTypes.string,
    handleMenuMessage: PropTypes.func,
    channel: PropTypes.string,
};

export default Nps;
