import { createTheme } from "@material-ui/core/styles";

const black = "#000000";
const blue = "#041E42";
const darkBlue = "#07336b";
const darkerBlue = "#004484"; // selections
const darkerGrey = "#909196";
const darkestBlue = "#002242"; // sidebar
const darkestGrey = "#74767c";
const darkGrey = "#babbbe";
const grey = "#d5d6d8";
const lightBlue = "#338de3";
const lightGrey = "#f8f8f8";
const lightYellow = "#fffcf4";
const pink = "#ef5ba1";
const red = "#9b1419";
const white = "#ffffff";
const yellow = "#f3b53c";
const lighterBlue = "#0071ce";

const palette = {
    common: {
        black,
        blue,
        darkBlue,
        darkerBlue,
        darkerGrey,
        darkestBlue,
        darkestGrey,
        darkGrey,
        grey,
        lightBlue,
        lightGrey,
        lightYellow,
        pink,
        red,
        white,
        yellow,
        lighterBlue,
        border: grey,
        buttonDisabled: darkGrey,
        buttonDisabledBackground: grey,
        buttonPrimary: white,
        buttonPrimaryBackground: blue,
        buttonSecondary: darkBlue,
        buttonSecondaryBackground: white,
        errorText: red,
        inputBorder: darkerGrey,
        inputDisabled: darkGrey,
        inputDisabledBackground: white,
        link: black,
        outageText: pink,
        placeholder: darkestGrey,
        selection: darkerBlue,
        sidebar: darkestBlue,
        subtext: darkBlue
    }
};

const typography = {
    fontFamily: "Bogle, \"Helvetica Neue\", sans-serif",
    fontSize: 16,
    fontWeight: 400,
    autocomplete: {
        fontWeight: 600
    },
    autocompleteInput: {
        fontSize: 16,
        lineHeight: 1.5,
    },
    autocompletePaper: {
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: 1.43,
    },
    body1: {
        fontSize: "1.0rem",
    },
    body2: {
        fontSize: "1.0rem",
    },
    h2: {
        fontSize: "3rem",
        fontWeight: 600,
    },
    h3: {
        fontSize: "2rem",
        fontWeight: 600,
    },
    h4: {
        fontSize: "1rem",
        fontWeight: 600,
    },
    h5: {
        fontSize: "0.75rem",
        fontWeight: 600,
    },
    subtitle2: {
        lineHeight: "1"
    },
};

const overrides = {
    MuiCssBaseline: {
        "@global": {
            "@font-face": "Bogle, \"Helvetica Neue\", sans-serif",
        },
    },
    MuiAutocomplete: {
        endAdornment: {
            top: "inherit"
        },
        input: {
            "&:first-child": {
                padding: "0 12px"
            }
        }
    },
    MuiChip: {
        root: {
            cursor: "pointer",
            fontSize: "0.875rem",
            height: "auto"
        },
        label: {
            fontSize: "0.875rem",
            height: "auto",
            overflowWrap: "break-word",
            padding: "0.3rem",
            textOverflow: "clip",
            whiteSpace: "normal",
        }
    },
    MuiInput: {
        underline: {
            "&:after": {
                borderBottom: "none"
            },
            "&:before": {
                content: "none"
            }
        }
    },
    MuiInputBase: {
        input: {
            border: "none",
            color: palette.common.black,
            fontFamily: typography.fontFamily,
            fontSize: typography.fontSize,
            fontWeight: typography.fontWeight,
            height: "48px",
            outline: "none",
            padding: "0 12px !important",
            "&:disabled": {
                backgroundColor: palette.common.inputDisabledBackground,
                color: `${palette.common.inputDisabled} !important`,
                opacity: "1 !important",
                textFillColor: palette.common.inputDisabled,
            },
            "&::placeholder": {
                color: `${palette.common.placeholder} !important`,
                opacity: "1 !important"
            },
        },
    },
    MuiOutlinedInput: {
        root: {
            padding: "4px 0"
        }
    },
    MuiSelect: {
        icon: {
            top: "auto"
        }
    },
    MuiTextField: {
        root: {
            width: "100%"
        }
    }
};

export default createTheme({
    palette,
    typography,
    overrides
});
