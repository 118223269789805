import React, { useState, useEffect } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { renewSession, createSession, getChatBotAppConfig, getLangCookie, checkIfGoogleApisReachable } from "./utils/utils";
import { LANGUAGES } from "./constants/languagesConstants";
import i18n from "./i18n";
//Custom files & components
import AppContext from "./AppContext";
import theme from "./ui/Theme";
import useGetUserDetails from "./components/HooksUtil/useGetUserDetails";
import useDisableConsoleLogs from "./components/HooksUtil/useDisableConsoleLogs";
import { routes } from "./routes";
import IdleAlert from "./components/IdleAlert/IdleAlert"
import { config }  from "./constants/contant"
import SpinnerComponent from "./components/common/SpinnerComponent";
// istanbul ignore next
const useStyles = makeStyles(() => ({
    mainContentContainer: {
        height: "100%",
        maxWidth: "100%"
    },
    spinnerContainer: {
      marginTop: "45vh",
      marginLeft: "45%"
    },
}));

const BotApp = () => {
    const classes = useStyles();
    const langCookie = getLangCookie() || localStorage.getItem("langCode");
    const lang =
      langCookie &&
      langCookie !== null &&
      Object.keys(LANGUAGES).some((ele) => LANGUAGES[ele].value === langCookie)
        ? langCookie
        : LANGUAGES.DEFAULT_LANG.value;
    localStorage.setItem("langCode", lang);
    i18n.changeLanguage(lang);
    const [chatBotAppConfig, setChatBotAppConfig] = useState({});
    const [isSsoSignInDone, setIsSsoSignInDone] = useState(false);
    const [isSsoSignInFailed, setIsSsoSignInFailed] = useState(false);
    const { userInfo } = useGetUserDetails();
    const timeout = config.sessionTimeout;

    useEffect(() => {
        Promise.allSettled([
        createSession(lang),
        getChatBotAppConfig(),
        ])
        .then(
            ([createSessionRes, getChatBotAppConfigRes]) => {
                if (
                    createSessionRes.status === "fulfilled" &&
                    getChatBotAppConfigRes.status === "fulfilled" &&
                    getChatBotAppConfigRes.value &&
                    getChatBotAppConfigRes.value.data &&
                    Object.keys(getChatBotAppConfigRes.value.data).length > 0 &&
                    !getChatBotAppConfigRes.value.data.errorType
                ) {
                    // if (
                    // isGoogleApisReachable.status === "fulfilled"
                    // ) {
                    // setChatBotAppConfig(getChatBotAppConfigRes?.value?.data);
                    // } else {
                    // console.log(
                    //     "Not Reachable due to : ",
                    //     isGoogleApisReachable.reason
                    // );
                    // setChatBotAppConfig({
                    //     ...getChatBotAppConfigRes?.value?.data,
                    //     EnableGoogleCaptcha: false,
                    // });
                    // }
                    setChatBotAppConfig({
                        ...getChatBotAppConfigRes?.value?.data,
                        EnableGoogleCaptcha:
                        getChatBotAppConfigRes?.value?.data?.EnableGoogleCaptcha &&
                        new Date().getTimezoneOffset() !== -480,
                    });
                    setIsSsoSignInDone(true);
                } else {
                    console.log("Error collecting pre-requisite Details");
                    setIsSsoSignInFailed(true);
                }
            }
        )
        .catch((err) => {
            console.log(err);
            setIsSsoSignInFailed(true);
        });
    }, []);

    console.log(timeout)
    const onIdle = () => {
        console.log("timeout")
        renewSession()
        reset()
    };
    const { reset } = useIdleTimer({
        onIdle,
        timeout,
    });

    useDisableConsoleLogs();
    return (
        <AppContext.Provider>
            <ThemeProvider theme={theme}>
                {!isSsoSignInDone && !isSsoSignInFailed && (
                  <div className={classes.spinnerContainer}>
                    <SpinnerComponent color={"gray"} />
                  </div>
                )}
                {isSsoSignInDone && (
                    <BrowserRouter>
                        <div className={classes.mainContentContainer}>
                            {routes(
                                userInfo, chatBotAppConfig, lang
                            )}
                        </div>
                        <IdleAlert />
                    </BrowserRouter>
                )}
                {isSsoSignInFailed && (
                    <div>
                        Ping Fed  Sign on Failed. Please try again.
                    </div>
                )}
            </ThemeProvider>
        </AppContext.Provider>
    );
};

export default BotApp;
