import { useState, useEffect } from "react";

export default function useGetUserDetails() {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const user = {
            "userId": "g0s077c",
            "firstName": "bar",
            "lastName": "foo",
            "chatAccess": true
        };
        setUserInfo(user);
    }, []);

    return { userInfo};
}
