import axios from "axios";
import { useContext } from "react";
// import { flags } from "../../config/env-config";
import ErrorContext from "../../ErrorContext";
// import { env } from "../../utils/utils";

export default function  useCreateChatItem() {
    // const { setStatusCode } = useContext(ErrorContext);
    const CREATE_CHAT_ITEM_URL = "/api/chathistory/createChatItem";
    const GET_CHAT_ITEM_URL = "/api/chathistory/getChatHistory";

    

    const saveChatItem = (chatItem, convId, chatStorageId) => {
        chatItem.forEach(item => {item.convId = convId;})
        let reqBody = {
            localStorageId: chatStorageId,
            messages: chatItem
        };

        reqBody = JSON.parse(JSON.stringify(reqBody));
        try {
            axios.post(CREATE_CHAT_ITEM_URL, reqBody);
        } catch (err) {
            console.log("Error axios-001", err.message);
            // setStatusCode(404);.
        }
    };
    function getChatItem(chatStorageId) {
        // console.log("getKeys ", service);
        const payload = { 
            "localStorageId": chatStorageId
        }
        return new Promise((resolve, reject) => {
            axios.post(GET_CHAT_ITEM_URL,payload).then(resp => {
               
                if(resp?.data?.messages?.length>0){
                    resolve(resp.data.messages);
                }else{
                    reject()
                }
                // resolve(resp.data.messages);
            }).catch(err => {
                reject(err);
            });
        });
    
    }
    return { saveChatItem, getChatItem };
}
