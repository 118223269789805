const url = document.location.hostname;
const localUrl = url.includes("localhost");
const devUrl = url.includes("sustainabilitychat-ui.dev.walmart.com");
const stageUrl =
  url.includes("sustainabilitychat-ui.qa.walmart.com") ||
  url.includes("walmartsustainabilityhub.chat.qa.walmart.com");
// const prodUrl = url.includes("sustainabilitychat-ui.walmart.com");

let env = "prod";
if (localUrl === true) {
  env = "local";
} else if (devUrl === true) {
  env = "dev";
} else if (stageUrl === true) {
  env = "stage";
}

export default env;
