import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { refreshSession } from "../../utils/utils";
import { config } from "../../constants/contant"
import CustomButton from "../common/CustomButton";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
    },
    body: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.95em",
        fontWeight: "normal"
    }
}));

const IdleAlert = () => {
    const classes = useStyles();
    const history = useHistory();
    const timeout = config.promptTimeout;
    const promptTimeout = config.promptPopupTimeout;
    const [open, setOpen] = useState(false);
    const [remaining, setRemaining] = useState(0);
    const onIdle = () => {
        // istanbul ignore else
        if (window.location.pathname !== "/logout") {
            setOpen(false);
            history.push("/logout");
        }
    };

    const onPrompt = () => {
        // istanbul ignore else
        if (window.location.pathname !== "/logout") {
            setRemaining(promptTimeout / 1000);
            setOpen(true);
        }
    };

    const {
        getRemainingTime,
        isPrompted,
        reset
    } = useIdleTimer({
        onIdle,
        onPrompt,
        promptTimeout,
        timeout,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);
        // istanbul ignore next
        return () => clearInterval(interval);
    }, [getRemainingTime, isPrompted]);

    const handleStillHere = () => {
        refreshSession();
        setOpen(false);
        reset();
    };
    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className={classes.root}
            onClose={handleStillHere}
            open={open}
        >
            <DialogTitle
                id="alert-dialog-title"
            >
                Are You Still There?
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    className={classes.body}
                    id="alert-dialog-description"
                >
                    Your session will be expired due to inactivity in {remaining} seconds.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    onClick={handleStillHere}
                >
                    Keep Me Active
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default IdleAlert;
