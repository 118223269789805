/* eslint-disable max-len */
import env from "./Env";

const dev = {
  env: "dev",
  adminApiBaseUrl:
    "https://dev.sustainability.platform.admin-service.walmart.com",
  chatbotAppUrl: "https://sustainabilitychat-ui.dev.walmart.com",
  GOOGLE_RECAPTCHA_SITE_KEY: "6Le3XhEqAAAAAHqOK003nnF8ob2H-iPOTW6d8p85",
};

const stage = {
  env: "stage",
  adminApiBaseUrl:
    "https://stage.sustainability.platform.walmart.com/admin-service",
  chatbotAppUrl: "https://walmartsustainabilityhub.chat.qa.walmart.com",
  GOOGLE_RECAPTCHA_SITE_KEY: "6Le3XhEqAAAAAHqOK003nnF8ob2H-iPOTW6d8p85",
};

const local = {
  env: "local",
  adminApiBaseUrl:
    "https://dev.sustainability.platform.admin-service.walmart.com",
  chatbotAppUrl: "http://localhost:3000",
  GOOGLE_RECAPTCHA_SITE_KEY: "6Le3XhEqAAAAAHqOK003nnF8ob2H-iPOTW6d8p85",
};

const prod = {
  env: "prod",
  adminApiBaseUrl: "https://sustainability.platform.walmart.com/admin-service",
  chatbotAppUrl: "https://walmartsustainabilityhub.chat.walmart.com",
  GOOGLE_RECAPTCHA_SITE_KEY: "6Lf_tSwqAAAAAG8rXeJ2vUbtz9iKvfjd_u_QNgZ4",
};

export default function envDetails() {
  switch (env) {
    case "dev":
      return dev;
    case "stage":
      return stage;
    case "prod":
      return prod;
    default:
      return local;
  }
}
