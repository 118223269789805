import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            key={index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography key={index}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    outerPageTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    divider: {
        background: "#F5F5F5",
        height: 2,
    },
    button: {
        "&:hover": {
            color: theme.palette.common.darkerBlue,
            textDecoration: "underline",
            background: "transparent",
            fontSize: 16,
            fontWeight: 600
        }
    },
    closeIcon: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    span: {
        marginLeft: "1em",
        textTransform: "none",
        color: theme.palette.common.darkerBlue,
        textAlign: "left"
    }
}));

const StyledTabs = withStyles((theme) => ({
    indicator: {
        backgroundColor: theme.palette.common.darkerBlue,
    }
}))(Tabs);
export default function PageTab(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [index, setindex] = React.useState(0);
    const [topicLevel, settopicLevel] = React.useState(0);
    const [selectedSubtopic, setselectedSubtopic] = React.useState(null);
    const [selectedSecondLeveltopic, setSecondLeveltopic] = React.useState(null);
    const options = ["ALL_TOPICS", "TOP_QUESTIONS_TEXT"];

    const [displayTopic, setDisplayTopic] = React.useState([]);
    const [displayTopQuestion, setDisplayTopQuestion] = React.useState([]);

    const handleChange = (event, newindex) => {
        setindex(newindex);
    };
    React.useEffect(() => {
        setDisplayTopQuestion(props.topics?.["top_questions"]);
        if (Object.keys(props.topics["all_topics"]).includes(props?.selectedSubtopic)) {
            setselectedSubtopic(props.selectedSubtopic);
            settopicLevel(1);
        }
    }, []);
    React.useEffect(() => {
        if (topicLevel == 0) {
            setDisplayTopic(Object.keys(props.topics["all_topics"]));
        } else if (topicLevel == 1) {
            setDisplayTopic(props.topics["all_topics"][selectedSubtopic]);
        } else if (topicLevel == 2) {
            setDisplayTopic(props.topics["all_topics"][selectedSubtopic][selectedSecondLeveltopic]);
        }
    }, [topicLevel]);
    const onClickBackhandle = () => {
        if (topicLevel == 2) {
            settopicLevel(1);
            setSecondLeveltopic(null);
        } else if (topicLevel == 1) {
            settopicLevel(0);
            setselectedSubtopic(null);
        } else if (topicLevel == 0) {
            props.handleClickOptions();
        }
    };
    const handleClickTopic = (e, d) => {
        if (topicLevel == 0) {
            setselectedSubtopic(d);
            settopicLevel(1);
        } else if (topicLevel == 1) {
            settopicLevel(0);
            setselectedSubtopic(null);
            setSecondLeveltopic(null);
            props.handleClickOptions(e, t(d));
        } else if (topicLevel == 2) {
            settopicLevel(0);
            setselectedSubtopic(null);
            setSecondLeveltopic(null);
            props.handleClickOptions(e, t(d));
        }
    };
    return (
        <div className={classes.outerPageTab}>
            <div className={classes.closeIcon}>
                <CloseIcon onClick={(e) => props.handleClickOptions(e, "")} />
            </div>

            <StyledTabs
                value={index}
                onChange={handleChange}
            >
                {options.map((option, ind) => (
                    <Tab label={t(option)}
                        key={ind}
                        style={{
                            color: "#004484",
                            fontSize: 15,
                            fontWeight: 800,
                        }}
                    />
                ))
                }
            </StyledTabs>
            <Divider classes={{ root: classes.divider }} />

            <ArrowBackIcon onClick={onClickBackhandle} style={{ padding: 5 }} />

            <div style={{ padding: 10 }}>
                <TabPanel value={index} index={0}>
                    {displayTopic.map((d, i) => (
                        <>

                            <Button size="small" key={i} onClick={(e) => handleClickTopic(e, d)} className={classes.button}>
                                <span className={classes.span}>{t(d)}</span>
                            </Button>
                            <br />
                        </>
                    ))}
                </TabPanel>
                <TabPanel value={index} index={1}>
                    {displayTopQuestion.map((d, i) => (
                        <>
                            <Button size="small" key={i} onClick={(e) => props.handleClickOptions(e, t(d))} className={classes.button}>
                                <span className={classes.span}>{t(d)}</span>
                            </Button>
                            <br />
                        </>
                    ))}
                </TabPanel>
            </div>
        </div>
    );
}
