import React from "react";
import PropTypes from "prop-types";
import TooltipIcon from "../../assets/Info.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// istanbul ignore next
const useStyles = makeStyles(() => ({
    tooltipIcon: {
        cursor: "pointer",
        marginTop: "5px",
    }
}));

// istanbul ignore next
const InfoTooltip = withStyles((theme) => ({
    arrow: {
        color: theme.palette.common.white
    },
    tooltip: {
        color: theme.palette.common.lightBlue,
        backgroundColor: theme.palette.common.white
    },
}))(Tooltip);

const TootipInfo = ({ message }) => {
    const classes = useStyles();

    return (
        <InfoTooltip arrow title={message}>
            <span className={classes.tooltipIcon}>
                <img src={TooltipIcon} alt="Tooltip Icon" />
            </span>
        </InfoTooltip>
    );
};

TootipInfo.propTypes = {
    message: PropTypes.string,
};

TootipInfo.defaultProps = {
    message: ""
};

export default TootipInfo;
