import React from "react";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import PageTab from "../PageTab/PageTab";
const useStyles = makeStyles((theme) => ({
    chatPage: {
        height: "100%",
        padding: 0,

    },
    paper: {
        zIndex: 1,
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.common.white,
    },

}));

export default function TopicPage(props) {
    const classes = useStyles();
    return (
        <div className={classes.chatPage}>
            <Slide direction="up" in={props.showTopic} mountOnEnter unmountOnExit timeout={500}>
                <Paper elevation={4} className={classes.paper}>
                    <PageTab topics={props.topics} handleClickOptions={props.handleClickOptions} selectedSubtopic={props.selectedSubtopic} />
                </Paper>
            </Slide>
        </div>
    );
}
