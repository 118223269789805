import { useState, useEffect } from "react";
import md5 from "md5";

export default function useCreateConversationId(userId) {
    const [convId, setConvId] = useState("");
    const getNewConvId = () => {
        const timestamp = new Date().getTime();
        const hashedId = md5(userId + timestamp);
        sessionStorage.setItem("convId", hashedId);
        return hashedId;
    };

    useEffect(() => {
        const hashedId = getNewConvId();
        setConvId(hashedId);
    }, []);
    
    return { convId };
}