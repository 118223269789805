import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Spark from "../../assets/spark.png";
import DefaultChatMessage from "./DefaultChatMessage";
import Loader from "../Loader/Loader";

// istanbul ignore next
const useStyles = makeStyles(theme => ({
    botMessage: {
        backgroundColor: "#e1edf9",
        borderRadius: "10px",
        borderBottomLeftRadius: "initial",
        color: theme.palette.common.black,
        display: "inline-block",
        // maxWidth: "calc(90% - 45px)",
        maxWidth: "calc(100% - 0px)",
        padding: "0.75em",
        wordWrap: "break-word",
        [theme.breakpoints.up("lg")]: {
            // maxWidth: "calc(55% - 45px)",
        },
    },
    botMessageContainer: {
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "flex-start",
        maxWidth: "100%",
        width: "100%",
    },
    botNameText: {
        color: theme.palette.common.black,
        fontSize: "0.75rem",
        fontWeight: "400",
        marginBottom: "4px"
    },
    chatMessageContainer: {
        margin: "1em 0",
        padding: "0 10px"
    },
    chatIconContainer: {
        alignItems: "center",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        padding: "0 0.625rem",
    },
    sparkIcon: {
        height: "30px",
        width: "28px",
        paddingRight: "5px",
    },
    userMessage: {
        backgroundColor: theme.palette.common.lightYellow,
        border: `1px solid ${theme.palette.common.border}`,
        borderRadius: "10px",
        borderBottomRightRadius: "initial",
        color: theme.palette.common.black,
        display: "inline-block",
        maxWidth: "90%",
        padding: "0.75em",
        wordWrap: "break-word",
        [theme.breakpoints.up("lg")]: {
            maxWidth: "55%",
        }
    },
    userMessageContainer: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        maxWidth: "100%",
        // padding: "0 1em",
        width: "100%",
    },
}));

const ChatConversation = (props) => {
    const {
        addChatPayload,
        chatModel,
        handleCancel,
        handleFormSubmit,
        isFileSelected,
        selectedFiles,
        setInputFocus,
        scrollToBottom,
        setIsUserInputDisabled,
        showFileUploadComponent,
        showLoader,
        showNpsFlag,
        userInfo,
        chatStorageId,
        handleMenuMessage,
        channel,
        chatBotAppConfig,
        lastConversation,
        handleCreateIncident
    } = props;
    const classes = useStyles();

    const fullName = `${userInfo.firstName} ${userInfo.lastName}`;

    const renderMessage = (message, index) => {
        const msgIsInteractive = (chatModel.length - 1) === index;
        let botMsgToRender;
        if (showLoader && message.inputDataType === "loader") {
            botMsgToRender = (
                <div>
                    <img
                        alt="bot avatar"
                        className={classes.sparkIcon}
                        src={Spark}
                    />
                    <div className={classes.botMessage}>
                        <Loader />
                    </div>
                </div>
            );
        } else {
            botMsgToRender = (
                <DefaultChatMessage
                    addChatPayload={addChatPayload}
                    fullName={fullName}
                    isFileSelected={isFileSelected}
                    message={message}
                    msgIsInteractive={msgIsInteractive}
                    selectedFiles={selectedFiles}
                    setIsUserInputDisabled={setIsUserInputDisabled}
                    showFileUploadComponent={showFileUploadComponent}
                    showNpsFlag={showNpsFlag}
                    chatStorageId={chatStorageId}
                    handleMenuMessage={handleMenuMessage}
                    channel={channel}
                    scrollToBottom={scrollToBottom}
                    chatBotAppConfig={chatBotAppConfig}
                    lastConversation={lastConversation}
                    handleCreateIncident={handleCreateIncident}
                />
            );
        }

        const messageBubble = message.event == "bot-startup"?<></> : message.sender === "bot"  ?
            <div className={classes.botMessageContainer}>
                {/* <div className={classes.chatIconContainer}>
                    <img
                        alt="bot avatar"
                        className={classes.sparkIcon}
                        src={Spark}
                    />
                </div> */}
                {botMsgToRender}
            </div>
            : 
            <div className={classes.userMessageContainer}>
                {/* <div className={classes.botNameText}>
                  you
                </div> */}

                <div
                    className={classes.userMessage}
                    name="user-message"
                >
                    {message.text}
                </div>
            </div>;
        return (
            <div key={index} className={classes.chatMessageContainer}>
                {messageBubble}
            </div>
        );
    };

    return (
        <div>
            {chatModel.map((message, index) => renderMessage(message, index))}
        </div>
    );
};

ChatConversation.propTypes = {
    addChatPayload: PropTypes.func.isRequired,
    chatModel: PropTypes.array.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
    isFileSelected: PropTypes.bool,
    selectedFiles: PropTypes.array,
    setInputFocus: PropTypes.func.isRequired,
    setIsUserInputDisabled: PropTypes.func.isRequired,
    showFileUploadComponent: PropTypes.bool,
    showLoader: PropTypes.bool,
    showNpsFlag: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
    scrollToBottom: PropTypes.func.isRequired,
    chatStorageId: PropTypes.string,
    handleMenuMessage: PropTypes.func.isRequired,
    channel: PropTypes.string,
};

export default ChatConversation;
