import axios from "axios";
export default function useCheckForHuman() {
  const checkForHuman = (captchaToken, captchaVerificationThreshold) => {
    const reqBody = JSON.parse(
      JSON.stringify({ captchaToken, captchaVerificationThreshold })
    );
    try {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const url = "/api/verify/human";
        axios
          .post(url, reqBody)
          .then((resp) => {
            if (resp?.data) {
              const latency = new Date().getTime() - startTime;
              const captchaResponse = {
                ...resp.data,
                verificationLatency: latency,
              };
              resolve(captchaResponse);
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (err) {
      console.log("Error axios-001", err.message);
    }
  };
  return { checkForHuman };
}
