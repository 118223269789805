import axios from "axios";
export default function useOscIncident() {
  const createOscIncident = (incidentPayload) => {
    const reqBody = JSON.parse(JSON.stringify(incidentPayload));
    try {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const url = "/api/osc/createIncident";
        axios
          .post(url, reqBody)
          .then((resp) => {
            if (resp?.data && resp?.data?.data) {
              const latency = new Date().getTime() - startTime;
              const oscResponse = { ...resp.data, oscLatency: latency };
              resolve(oscResponse);
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (err) {
      console.log("Error axios-001", err.message);
    }
  };

  const checkIfUserEmailIdExists = (userEmailId) => {
    try {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const url = "/api/osc/verifyEmailId?userEmailId=" + userEmailId;
        axios
          .get(url)
          .then((resp) => {
            if (resp?.data && resp?.data?.success) {
              const latency = new Date().getTime() - startTime;
              const oscResponse = { ...resp.data, oscLatency: latency };
              resolve(oscResponse);
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (err) {
      console.log("Error axios-001", err.message);
    }
  };

  const fetchOscIncidentIssueTypeLevel1List = () => {
    try {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const url = "/api/osc/incidentIssueType";
        axios
          .get(url)
          .then((resp) => {
            if (
              resp?.data &&
              resp?.data?.level1 &&
              resp?.data?.level1?.length > 0
            ) {
              resolve(resp?.data?.level1);
            } else {
              reject(
                "No item present in Fetch Osc Incident Issue Type Level1 List."
              );
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (err) {
      console.log("Error axios-001", err.message);
    }
  };

  return {
    createOscIncident,
    checkIfUserEmailIdExists,
    fetchOscIncidentIssueTypeLevel1List,
  };
}
