import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ChipInput from "./ChipInput";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getPostFeedbackMessagePayload } from "../common/PayloadProvider";
import CustomButton from "../common/CustomButton";
import ErrorContext from "../../ErrorContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CreateIncidentForm } from "../ChatModals/CreateIncidentForm";
import envDetails from "../../utils/Config";

// istanbul ignore next
const useStyles = makeStyles(() => ({
    clearWrapper: {
        marginRight: "0.5em"
    },
    confirmButtons: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "1em",
        marginTop: "0.5em"
    },
    feedbackArea: {
        width: "100%"
    },
    inputWrapper: {
        display: "flex"
    },
    raiseTicketBtn: {
        height: "2rem",
        marginTop: "0.2rem",
        marginLeft: "0.5rem",
        fontSize: "80%"
    }
}));

const FeedbackInput = (props) => {
    const classes = useStyles();
    // const { setStatusCode } = useContext(ErrorContext);
    const { t } = useTranslation();
    const {
      addChatPayload,
      setIsUserInputDisabled,
      channel,
      chatBotAppConfig,
      lastConversation,
      handleCreateIncident,
      scrollToBottom,
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalOpen = () => setIsModalOpen(true);
    const [feedback, setFeedback] = useState(null);
    const [feedbackComment, setFeedbackComment] = useState("");
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [isIncidentCreated, setIsIncidentCreated] = useState(false);
    const [showFeedbackComment, setShowFeedbackComment] = useState(false);
    const [feedbackResponseType, setfeedbackResponseType] = useState(null);
    // useEffect(() => {
    //     setIsUserInputDisabled(true);
    // }, []);

    const saveUserFeedback = (e, feedback) => {
        const feedbackReq = feedback === "👍" ? "ThumbsUp" : "ThumbsDown";
        setFeedback(feedbackReq);
        setShowFeedbackComment(true);
        setfeedbackResponseType(feedbackReq);
        const userReactionPayload = {
          ...lastConversation,
          "feedback_reaction": feedbackReq,
          "feedback_response": "",
          "localStorageId": props.chatStorageId,
          "feedbackType": "thumb_feedback",
        };
        scrollToBottom();
        saveFeedback(userReactionPayload);
    };

    const saveUserFeedbackComment = (e) => {
        const feedbackComment = e.target.value;
        setFeedbackComment(feedbackComment);
    };

    const onClear = () => {
        setShowFeedbackComment(false);
        setFeedback(null);
        setFeedbackComment("");
        setfeedbackResponseType(null);
    };

    const submitFeedback = () => {
        // const reqBody = {
        //     "feedback": feedback, //1 - thumbs up 0 - Thumbs down
        //     "comments": feedbackComment
        // };
        const reqBody =    {
            ...lastConversation,
            "feedback_response": feedbackComment,   
            "feedback_reaction": feedback,   
            "localStorageId": props.chatStorageId,
            "feedbackType": "thumb_feedback",
            "channelId": channel
            }
        const payload = getPostFeedbackMessagePayload();
        addChatPayload(payload);
        setIsUserInputDisabled(false);
        setFeedbackSent(true);
        console.log(reqBody)
        saveFeedback(reqBody);
    };

    const saveFeedback = (reqBody) => {
        axios.post("/api/chathistory/saveUserFeedback", reqBody)
            .then((resp) => {
                console.log(resp)
                // axios.get(`/api/chatModel/getChatOptions`)
                //     .then(response => {
                //         const payload = getPostFeedbackMessagePayload();
                //         payload.options = response.data;
                //         addChatPayload(payload);
                //         setIsUserInputDisabled(false);
                //     });
            })
            .catch(err => {
                console.log("Error axios-008", err.message);
                // setStatusCode(404);
            });
    }

    return (
        <>
            <div className={classes.inputWrapper}>
                <ChipInput
                    disabled={feedbackSent}
                    ghostBtn={feedback === "ThumbsUp" ? true : false}
                    handleClickOption={saveUserFeedback}
                    icon={<ThumbUpAltOutlinedIcon />}
                    isIcon={true}
                    option="👍"
                    // option="helpful"
                />
                <ChipInput
                    disabled={feedbackSent}
                    ghostBtn={feedback === "ThumbsDown" ? true : false}
                    handleClickOption={saveUserFeedback}
                    icon={<ThumbDownOutlinedIcon />}
                    isIcon={true}
                    option="👎"
                    // option="unhelpful"
                />
                {chatBotAppConfig?.EnableIncidentMgmtOSC && (
                    <>
                        <CustomButton
                        color="primary"
                        className={classes.raiseTicketBtn}
                        variant="primary"
                        type="button"
                        disabled={isIncidentCreated}
                        handleClick={handleModalOpen}
                        >
                        {t("INCIDENT_MGMT.CREATE_INCIDENT_TEXT")}
                        </CustomButton>
                        {isModalOpen &&
                        (chatBotAppConfig?.EnableGoogleCaptcha ? (
                            <GoogleReCaptchaProvider
                            reCaptchaKey={envDetails().GOOGLE_RECAPTCHA_SITE_KEY}
                            >
                            <CreateIncidentForm
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                chatBotAppConfig={chatBotAppConfig}
                                setIsIncidentCreated={setIsIncidentCreated}
                                handleCreateIncident={handleCreateIncident}
                            />
                            </GoogleReCaptchaProvider>
                        ) : (
                            <CreateIncidentForm
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            chatBotAppConfig={chatBotAppConfig}
                            setIsIncidentCreated={setIsIncidentCreated}
                            handleCreateIncident={handleCreateIncident}
                            />
                        ))}
                    </>
                )}
            </div>
            {showFeedbackComment && (
                <>
                    <Typography variant="h4" component="span" style={{ paddingTop: "5px" }}>
                        {t("CHATBOT_COMMONS.THANKS_FOR_FEEDBACK_MESSAGE")}
                    </Typography> 
                    <br />
                    <br />
                    <TextareaAutosize
                        aria-label="minimum height"
                        placeholder={t("CHATBOT_COMMONS.FEEDBACK_BOX_PLACEHOLDER_MESSAGE")}
                        className={classes.feedbackArea}
                        disabled={feedbackSent}
                        minRows={4}
                        onChange={saveUserFeedbackComment}
                        value={feedbackComment}
                    />
                    <div className={classes.confirmButtons}>
                        <div className={classes.clearWrapper}>
                            <CustomButton
                                color="default"
                                disabled={feedbackSent}
                                handleClick={onClear}
                                id="clear"
                                size="small"
                                variant="secondary"
                            >
                                {t("CHATBOT_COMMONS.CLEAR_TEXT")}
                            </CustomButton>
                        </div>
                        <div>
                            <CustomButton
                                color="primary"
                                disabled={feedbackSent || feedbackComment.trim().length === 0}
                                handleClick={submitFeedback}
                                id="submit"
                                size="small"
                                variant="primary"
                            >
                                {t("CHATBOT_COMMONS.SUBMIT_TEXT")}
                            </CustomButton>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

FeedbackInput.propTypes = {
    addChatPayload: PropTypes.func.isRequired,
    name: PropTypes.string,
    setIsUserInputDisabled: PropTypes.func.isRequired,
    channel: PropTypes.string,
};

export default FeedbackInput;
