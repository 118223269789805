import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import Axios from "axios";
import envDetails from "./utils/Config";
import { getLangCookie } from "./utils/utils";
import { LANGUAGES } from "./constants/languagesConstants";

i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: LANGUAGES.DEFAULT_LANG.value,
    lng:
      getLangCookie() ||
      localStorage.getItem("langCode") ||
      LANGUAGES.DEFAULT_LANG.value,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath:
        envDetails().env !== "local"
          ? `${envDetails().adminApiBaseUrl}` +
            "/translations/readfile/sustainability_chatbot/{{lng}}"
          : "http://localhost:3000/translations/" +
            `{{lng}}` +
            "_translation.json",
      parse: (data) => {
        return data;
      },
      request: (options, url, payload, callback) => {
        Axios.get(url)
          .then((res) => {
            envDetails().env !== "local"
              ? callback(null, res.data)
              : callback(null, res);
          })
          .catch((err) => {
            Axios.get(
              `${envDetails().chatbotAppUrl}/translations/${
                localStorage.getItem("langCode") || LANGUAGES.DEFAULT_LANG.value
              }_translation.json`
            )
              .then((res) => {
                callback(null, res);
              })
              .catch((err) => {
                window.alert(
                  "Something Went Wrong Please Try Again In Sometime"
                );
              });
          });
      },
    },
  });

export default i18n;
