export const LANGUAGES = {
  ENGLISH: {
    label: "ENGLISH_LABEL",
    value: "en",
  },
  CHINESE: {
    label: "CHINESE_LABEL",
    value: "zh",
  },
  SPANISH: {
    label: "SPANISH_LABEL",
    value: "es",
  },
  DEFAULT_LANG: {
    label: "ENGLISH_LABEL",
    value: "en",
  },
};
