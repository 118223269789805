import React, { useCallback, useState } from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useCheckForHuman from "../HooksUtil/useCheckForHuman";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Spark from "../../assets/spark.png";
import { RefreshTwoTone } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.lightGrey,
    width: "100%",
  },
  captchaSectionContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: "1rem 1rem 0.5rem 1rem",
    width: "15rem",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    border: "2px solid rgba(4, 31, 65, 0.1)",
    borderRadius: "0.2rem",
    boxShadow: "24",
  },
  captchaCheckBox: {
    color: "#99d3a9",
    "&.Mui-checked": {
      color: "#34A853",
    },
  },
  progressBar: {
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: "#99d3a9",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#34A853",
      },
    },
  },
  walbotIcon: {
    height: "2rem",
    marginTop: "0.4rem",
    position: "absolute",
  },
  captchaErrMsg: {
    color: "#ff0000",
    fontSize: "0.8rem",
    cursor: "pointer",
  },
  actionBox: {
    height: "1rem",
    alignContent: "center",
  },
  refreshIcon: {
    fontSize: "1rem",
  },
}));

export const CheckBoxCaptcha = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { checkForHuman, checkFr } = useCheckForHuman();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isChecked, setIsChecked] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const { setIsHuman, chatBotAppConfig } = props;
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [captchaVerificationErrMsg, setCaptchaVerificationErrMsg] = useState(
    t("CHATBOT_COMMONS.REFRESH_MESSAGE")
  );

  const handleReCaptchaVerify = useCallback(async () => {
    setIsChecked(!isChecked);
    setIsVerifying(!isVerifying);
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    try {
      const token = await executeRecaptcha();
      checkForHuman(token, chatBotAppConfig?.CaptchaVerificationThreshold)
        .then((res) => {
          setIsVerifying(false);
          setIsHuman(res.success);
        })
        .catch((error) => {
          setIsVerifying(false);
          console.log("Error CAPTCHA-001", error);
        });
      return token;
    } catch (err) {
      console.log(err);
      return;
    }
  }, [executeRecaptcha]);

  return (
    <Grid item className={classes.captchaSectionContainer} xs={12}>
      <FormControlLabel
        label={t("CHATBOT_COMMONS.NOT_A_ROBOT_MESSAGE")}
        control={
          <Checkbox
            className={classes.captchaCheckBox}
            checked={isChecked}
            onChange={handleReCaptchaVerify}
            disabled={isChecked}
          />
        }
      />
      <img alt="logo" className={classes.walbotIcon} src={Spark} />
      <div className={classes.actionBox}>
        {isChecked && isVerifying && (
          <LinearProgress className={classes.progressBar} />
        )}
        {isChecked && !isVerifying && (
          <span
            className={classes.captchaErrMsg}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => window.location.reload(true)}
          >
            <RefreshTwoTone className={classes.refreshIcon} />
            {captchaVerificationErrMsg}
          </span>
        )}
      </div>
    </Grid>
  );
};
