import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/Home/Home";
import LogoutLanding from "./components/Logout/LogoutLanding";

export const routes = (userInfo, chatBotAppConfig, lang) => (
    <Switch>
        <Route
            exact
            path="/"
            component={
                // istanbul ignore next
                () => <Home userInfo={userInfo} lang={lang} chatBotAppConfig={chatBotAppConfig} />
            }
        />
        <Route
            exact
            path="/chat"
            component={
                // istanbul ignore next
                () => <Home userInfo={userInfo} lang={lang} chatBotAppConfig={chatBotAppConfig} />
            }
        />
          <Route
            exact
            path="/logout"
            component={LogoutLanding}
        />
       
    </Switch>
);
