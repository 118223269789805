import React, { useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppContext from "../../AppContext";
import CustomButton from "../common/CustomButton";
import ChatHeader from "../ChatHeader/ChatHeader";
import { deleteSession } from "../../utils/utils"
// istanbul ignore next
const useStyles = makeStyles(theme => ({
    center: {
        fontSize: "2rem",
        fontWeight: 600,
        lineHeight: 1.167,
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    logoutContainer: {
        position: "absolute",
        top: "40%",
    },
    profileArea: {
        height: "60px",
        padding: theme.spacing(0, 1),
        position: "absolute",
        top: 0,
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            height: "4em",
            padding: theme.spacing(2, 3),
        },
        [theme.breakpoints.down("xs")]: {
            backgroundColor: theme.palette.common.blue,
        }
    },
}));

const LogoutLanding = () => {
    const classes = useStyles();

    useEffect(() => {
        console.log("call logout session")
        deleteSession();
    }, []);

    const handleLogIn = () => {
        window.location = "/";
    };

    return (
        <>
        <Grid item container className={classes.profileArea}>
        <ChatHeader/>

        </Grid>
            <Grid
            alignItems="center"
            className={classes.logoutContainer}
            container
            direction="column"
            justifyContent="center"
        >
            <Grid item className={classes.center}>
                Your session has expired
            </Grid>
            <Grid item>
                <CustomButton
                    color="primary"
                    handleClick={handleLogIn}
                    size="medium"
                >
                    Continue to chat
                </CustomButton>
            </Grid>
        </Grid>
        </>
       
    );
};

export default LogoutLanding;
