import React, { useState } from "react";
import i18n from "../../i18n";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  FormControl,
  InputBase,
  Select,
  MenuItem,
} from "@material-ui/core";
import { LANGUAGES } from "../../constants/languagesConstants";
import Globe from "../../assets/Globe.png";

const LanguageDropdownInput = withStyles((theme) => ({
  input: {
    position: "relative",
    color: theme.palette.common.white,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      color: theme.palette.common.white,
    },
  },
}))(InputBase);

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
  languageDropdownGlobe: {
    height: "1em",
  },
  btnLogoutMain: {
    backgroundColor: "inherit",
    color: "inherit",
    paddingRight: 0,
  },
  headerTitle: {
    color: theme.palette.common.white,
    marginRight: "1em",
    marginTop: "0.25em",
  },
  loggedInAs: {
    [theme.breakpoints.up("lg")]: {
      paddingRight: "0.5rem",
    },
  },
  rightSideWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "0.5rem",
  },
  title: {
    color: theme.palette.common.white,
    fontSize: "18px",
    fontWeight: 500,
  },
  titleWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "start",
    },
  },
  languageDropdownWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "end",
    },
  },
  userName: {
    fontWeight: "bold",
  },
  userProfile: {
    alignItems: "flex-start",
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
  userProfileWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  walbotIcon: {
    height: "18px",
    marginRight: theme.spacing(1),
  },
  yellowDivider: {
    backgroundColor: theme.palette.common.yellow,
    borderRadius: "1px",
    height: "1px",
    padding: theme.spacing(0.5, 0, 0, 0),
    width: "45px",
  },
}));

const TranslationComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const myDate = new Date();
  myDate.setMonth(myDate.getMonth() + 12);

  const { lang, reflectLanguageChange } = props;
  const [language, setLanguage] = useState(lang);
  const languageDropdownList = [
    { label: LANGUAGES.ENGLISH.label, value: LANGUAGES.ENGLISH.value },
    { label: LANGUAGES.CHINESE.label, value: LANGUAGES.CHINESE.value },
    { label: LANGUAGES.SPANISH.label, value: LANGUAGES.SPANISH.value },
  ];

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value)
    reflectLanguageChange(event.target.value);
    localStorage.setItem("langCode", event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Grid className={classes.languageDropdownWrapper} item xs={6}>
      <img className={classes.languageDropdownGlobe} alt="logo" src={Globe} />
      <FormControl style={{ width: "6rem" }}>
        <Select
          id="demo-customized-select-native"
          value={language}
          onChange={handleLanguageChange}
          className="languageDropdown"
          input={<LanguageDropdownInput />}
        >
          {languageDropdownList &&
            languageDropdownList.map((lang) => {
              return (
                <MenuItem value={lang.value} key={lang.label}>
                  {t(lang.label)}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default TranslationComponent;
