import { useState, useEffect } from "react";
import { env } from "../../utils/utils";

export default function useDisableConsoleLogs() {
    const [isLogsDisabled, setIsLogsDisabled] = useState(false);

    const disbleConsoleLogsOffDevEnv = () => {
        if (env !== "dev" && env !== "stage" && env !== "qa") {
            console.log = () => { };
            console.warn = () => { };
            console.debug = () => { };
            console.info = () => { };
            console.error = (env === "prod") ? () => { } : console.error;
            setIsLogsDisabled(true);
        }
    };

    useEffect(() => {
        disbleConsoleLogsOffDevEnv();
    });

    return { isLogsDisabled };
}
