import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import TootipInfo from "../common/TooltipInfo";
import { useTranslation } from "react-i18next";

// istanbul ignore next
const useStyles = makeStyles(theme => ({
    ghostButton: {
        backgroundColor: `${theme.palette.common.buttonSecondary} !important`,
        color: `${theme.palette.common.buttonSecondaryBackground} !important`,
        height: "100%",
        padding: "0.75em",
        "&:hover": {
            backgroundColor: `${theme.palette.common.selection} !important`,
            color: `${theme.palette.common.buttonSecondaryBackground} !important`,
        }
    },
    root: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.common.inputBorder,
        borderRadius: "5px",
        boxSizing: "border-box",
        color: theme.palette.common.black,
        height: "auto",
        margin: "calc(0.2em + 1px)", // extra 1px avoids shifting on hover
        // marginRight: '0.1px',
        maxWidth: "100%",
        padding: 0,
        "&:disabled": {
            backgroundColor: `${theme.palette.common.buttonDisabledBackground} !important`,
            color: `${theme.palette.common.buttonDisabled} !important`
        },
        "&:hover": {
            // backgroundColor: `${theme.palette.common.white} !important`,
            border: "2px solid",
            margin: "0.2em"
        }
    },
    chipDanger: {
        borderColor: `${theme.palette.common.red} !important`,
        color: `${theme.palette.common.red} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.common.red} !important`,
            color: `${theme.palette.common.white} !important`,
        }
    },
    arrowIcon: {
        display: "none"
    }
}));

const ChipInput = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        disabled,
        ghostBtn,
        handleClickOption,
        label,
        option,
        selectedMenu,
        type
    } = props;

    const isDataObject = typeof option === "object";

    return (
        <>
            <Chip
                className={clsx(classes.root, {
                    [classes.ghostButton]: ghostBtn,
                    [classes.chipDanger]: type && type?.toLowerCase() === "danger"
                })}
                disabled={disabled}
                label={t(option.option ?? option.displayData ?? label ?? option)}
                onClick={
                    // istanbul ignore next
                    (e) => {
                        if (option.option) {
                            selectedMenu(e, option);
                        } else {
                            handleClickOption(e, option.displayData ?? option);
                        }
                    }
                }
                variant="outlined"
            />

            {isDataObject && option.infoData && <TootipInfo message={option.infoData} />}
        </>
    );
};

ChipInput.propTypes = {
    disabled: PropTypes.bool,
    ghostBtn: PropTypes.bool,
    handleClickOption: PropTypes.func.isRequired,
    label: PropTypes.string,
    option: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    selectedMenu: PropTypes.func,
    type: PropTypes.string,
};

export default ChipInput;
