import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChatSection from "./ChatSection";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CheckBoxCaptcha } from "./CheckBoxCaptcha";
import envDetails from "../../utils/Config";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.lightGrey,
    width: "100%",
  },
}));

const Home = (props) => {
  const { userInfo, chatBotAppConfig, lang } = props;
  const classes = useStyles();
  const [isHuman, setIsHuman] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container className={classes.defaultContainer}>
        {isHuman || !chatBotAppConfig?.EnableGoogleCaptcha ? (
          <Grid item className={classes.chatSectionContainer} xs={12}>
            <ChatSection
              userInfo={userInfo}
              isRRPermission={true}
              chatBotAppConfig={chatBotAppConfig}
              lang={lang}
            />
          </Grid>
        ) : (
          <GoogleReCaptchaProvider
            reCaptchaKey={envDetails().GOOGLE_RECAPTCHA_SITE_KEY}
          >
            <CheckBoxCaptcha setIsHuman={setIsHuman} chatBotAppConfig={chatBotAppConfig} />
          </GoogleReCaptchaProvider>
        )}
      </Grid>
    </div>
  );
};

Home.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default Home;
