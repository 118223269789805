import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Spark from "../../assets/spark.png";
import { useTranslation } from "react-i18next";
import TranslationComponent from "./TranslationComponent";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    fontSize: "18px",
    fontWeight: 500,
  },
  titleWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "start",
    },
  },
  walbotIcon: {
    height: "18px",
    marginRight: theme.spacing(1),
  },
}));

const ChatHeader = (props) => {
  const classes = useStyles();
  const { lang, reflectLanguageChange, chatBotAppConfig } = props;
  const { t } = useTranslation();
  return (
    <>
      <Grid container alignItems="center">
        <Grid className={classes.titleWrapper} item xs={6}>
          <img alt="logo" className={classes.walbotIcon} src={Spark} />
          <div className={classes.title}>
            <div> {t("HEADER_TITLE")} </div>
          </div>
        </Grid>
        {chatBotAppConfig?.EnableQNA && (
          <TranslationComponent
            lang={lang}
            reflectLanguageChange={reflectLanguageChange}
          />
        )}
      </Grid>
    </>
  );
};

ChatHeader.propTypes = {
  userInfo: PropTypes.object,
};

export default ChatHeader;
