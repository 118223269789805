import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FeedbackInput from "../ChatInputs/FeedbackInput";
import Markdown from "../common/Markdown";
import Nps from "../ChatInputs/Nps";

// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    botMessage: {
        backgroundColor: "#e1edf9",
        borderRadius: "10px",
        borderBottomLeftRadius: "initial",
        color: theme.palette.common.black,
        display: "inline-block",
        // maxWidth: "calc(90% - 45px)",
        maxWidth: "calc(100% - 0px)",
        padding: "0.75em",
        wordWrap: "break-word",
        [theme.breakpoints.up("lg")]: {
            // maxWidth: "calc(55% - 45px)",
        },
    },
    botMessageOuter: {
        width: "calc(100% - 65px)" // subtract icon width
    },
    botNameText: {
        color: theme.palette.common.black,
        fontSize: "0.75rem",
        fontWeight: "400",
        marginBottom: "4px"
    },
    detail: {
        display: "block",
        marginBottom: "14px",
        [theme.breakpoints.up("lg")]: {
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
        },
    },
    detailField: {
        fontWeight: "bold",
        [theme.breakpoints.up("lg")]: {
            width: "30%",
        },
    },
    detailValue: {
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            width: "70%",
        },
    },
    hyperlinkPrivacy: {
        color: theme.palette.common.blue,
        fontWeight: "normal"
    },
    link: {
        color: theme.palette.common.link
    },
    subText: {
        color: theme.palette.common.darkBlue,
        fontStyle: "italic",
        fontWeight: "normal"
    },
    text: {
        color: theme.palette.common.black,
        fontWeight: "normal"
    },
}));

const DefaultChatMessage = (props) => {
    const {
        addChatPayload,
        fullName,
        isFileSelected,
        message,
        msgIsInteractive,
        setIsUserInputDisabled,
        showFileUploadComponent,
        showNpsFlag,
        chatStorageId,
        handleMenuMessage,
        channel,
        chatBotAppConfig,
        lastConversation,
        handleCreateIncident,
        scrollToBottom
    } = props;
    const classes = useStyles();
    const linkText = message.linkText || "Link";

    const renderDefaultMessageBody = (message, msgIsInteractive) => {
        return (<>
            {message?.subtext ? (
                <>
                    <br />
                    <br />
                    <Typography gutterBottom variant="h4" component="span">
                        <div className={classes.subText}>
                            <Markdown
                                text={message.subtext}
                            />
                        </div>
                    </Typography>
                </>
            ) : null}
            {message?.hyperlinkurl ? (
                <Typography gutterBottom variant="h4" component="span">
                    <a
                        href={message?.hyperlinkurl}
                        className={classes.hyperlinkPrivacy}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {message?.hyperlinkurl}
                    </a>
                </Typography>
            ) : null}
            {message?.feedbackType == "nps_feedback" && 
             <Nps
             addChatPayload={addChatPayload}
             setIsUserInputDisabled={setIsUserInputDisabled}
             chatStorageId={chatStorageId}
             handleMenuMessage={handleMenuMessage}
             channel={channel}
         />}
          {message?.feedbackType == "thumbs_feedback" && 
             <div style={{ paddingTop: "5px" }}>
             <FeedbackInput
                 addChatPayload={addChatPayload}
                 setIsUserInputDisabled={setIsUserInputDisabled}
                 chatStorageId={chatStorageId}
                 channel={channel}
                 scrollToBottom={scrollToBottom}
                 chatBotAppConfig={chatBotAppConfig}
                 lastConversation={lastConversation}
                 handleCreateIncident={handleCreateIncident}
             />
         </div>}
        </>);
    };

    return (
        <div className={classes.botMessageOuter}>
           
            <div className={classes.botMessage}>
                <Typography gutterBottom variant="h4" component="span" style={{ whiteSpace: "pre-line" }}>
                    <div className={classes.text}>
                        <Markdown
                            text={message.text || message.shortText}
                        />

                        {message.link && [
                            " ",
                            <a
                                key={0}
                                className={classes.link}
                                href={message.link}
                            >
                                {linkText}
                            </a>
                        ]}
                    </div>
                </Typography>
                {renderDefaultMessageBody(message, msgIsInteractive)}
            </div>
        </div>
    );
};

DefaultChatMessage.propTypes = {
    addChatPayload: PropTypes.func.isRequired,
    fullName: PropTypes.string,
    isFileSelected: PropTypes.bool,
    message: PropTypes.object.isRequired,
    msgIsInteractive: PropTypes.bool,
    setIsUserInputDisabled: PropTypes.func.isRequired,
    setSelectedFiles: PropTypes.func,
    showFileUploadComponent: PropTypes.bool,
    showNpsFlag: PropTypes.bool,
    chatStorageId: PropTypes.string,
    handleMenuMessage: PropTypes.func.isRequired,
    channel: PropTypes.string,
};

export default DefaultChatMessage;
